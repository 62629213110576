import { Card } from "antd";

export default function WesterUnion() {
  return (
    <Card title="Western Union">
      <p>
        Please send money by Western Union or MoneyGram IN PERSON at a local
        agency (DO NOT send online or buy money order)
        <br />
        <br />
        <strong>Receiver Information:</strong>
        <br />
        First Name: Zhennan
        <br />
        Last Name: Kuang
        <br />
        Country: China
        <br />
        City: Guangzhou
        <br />
        <br />
        After sending the money, please email us the following information:
        <ol>
          <li>MTCN/Reference Number</li>
          <li>Sender's Full Name</li>
          <li>Amount Transferred</li>
          <li>Receiver's Name (Zhennan Kuang)</li>
          <li>Picture/Scan of the Money Transfer Receipt</li>
        </ol>
        Please send the details to:{" "}
        <a href="mailto:service@getyourfakeid.com">service@getyourfakeid.com</a>
      </p>
    </Card>
  );
}
